.app {
  &__title {
    font-weight: 500;
  }
  display: grid;
  place-content: center;
  margin-top:3rem;
  &__example {
    margin-bottom: 4rem;
  }
}